<template lang="pug">

div#app(:class="[layoutClassName]" ref="app")
  div#paused(v-if="isPaused")
    comp-replay(@click.native="handleRePlay")
  div#header(v-if="isConfigLoaded")
    //comp-header(v-if="!$isMobile")
    comp-header-mobile(v-if="$isMobile")
  div#intro(v-if="$isMobile")
    comp-play(@click.native="handlePlay" v-if="isConfigLoaded")
  div#content(v-if="isConfigLoaded")
    div#player(:style="{ 'background-image': 'url(' + coverUrl + ')' }" v-if="$isMobile")
      //comp-player(v-if="!$isMobile" ref="player")
      comp-player-mobile(v-if="$isMobile" ref="player")
  div#interact(v-if="isConfigLoaded")
    div#message
      comp-message(ref="message"
        :isMobileVerticalLayout="isMobileVerticalLayout"
        :isMobileHorizontalLayout="isMobileHorizontalLayout")
  //如果是PC端，显示二维码
  div#PC-show-qrcode-page(v-if="!$isMobile")
    div.show-qrcode-img(ref="qrcodeContainer")
    span.show-qrcode-tips 请扫描二维码进入直播间
</template>

<script>
// import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import compHeader from '@/components/comp-header/index';
import compHeaderMobile from '@/components/comp-header/index-mobile';
import compPlayer from '@/components/comp-player/index';
import compPlayerMobile from '@/components/comp-player/index-mobile';
import compPlay from '@/components/comp-player/stream-control/comp-play';
import compReplay from '@/components/comp-player/stream-control/comp-replay';
import compMessage from '@/components/comp-message';
import liveRoomUrlQrcode from '@/assets/img/live_room_url_qrcode.png';

import layout from './layout.js';

import {

  SET_SDK_APP_ID,
  SET_USER_SIG,
  SET_PLAYER_DOMAIN,
  SET_ROOM_ID,
  SET_ROOM_NAME,
  SET_ROOM_INTRO,
  SET_ROOM_COVER,
  UPDATE_USER_INFO,
  SET_ANCHOR_USER_INFO,
  SET_ANCHOR_USER_ID,
  SET_IS_SUPPORT_WEBRTC,
  UPDATE_PLAY_STATE,
  SET_ROOM_ONLINE_COUNT,
  SET_ROOM_PROP,
  UPDATE_LINE_TYPE,
} from '@/constants/mutation-types';

import {
  LINE_TYPE,
} from '@/constants/room';

import { mapState } from 'vuex';
import TRTC from 'trtc-js-sdk';

import { fetchWechatJsSdkConfig, getActiveUid, getLiveInfo, addUserRecommendRecord } from './api/liveRoom';
// import TIM from "tim-js-sdk";


export default {
  name: 'App',
  mixins: [layout],
  data() {
    return {
      isConfigLoaded: false,
      coverUrl: 'https://wanwuxiu.oss-cn-qingdao.aliyuncs.com/attach/2024/04/56477202404272017187719.png',
      roomShareImage: 'http://cremb-zsff.oss-cn-beijing.aliyuncs.com/68244202002171745063469.jpg',
      uid: 0,
      share_user_id: 0,
      isStart: false,
      isPaused: false,
      liveRoomUrlQrcode,
    };
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
      roomId: 'roomId',
      roomName: 'roomName',
      roomIntro: 'roomIntro',
      roomProp: 'roomProp',
      roomOnlineCount: 'roomOnlineCount'
    }),
  },
  components: {
    compHeader,
    compHeaderMobile,
    compPlayer,
    compPlayerMobile,
    compMessage,
    compPlay,
    compReplay
  },
  methods: {
    handlePlay(event) {
      event.stopPropagation();
      this.$store.commit(UPDATE_PLAY_STATE, 'playing');
      this.isStart = true;
    },
    // 处理是否支持 webRTC
    async handleSupportWebRTC() {
      const supportResult = await TRTC.checkSystemRequirements();
      const { isWebRTCSupported, isH264DecodeSupported } = supportResult.detail;
      if (!isWebRTCSupported || !isH264DecodeSupported) {
        this.$store.commit(SET_IS_SUPPORT_WEBRTC, false);
        this.$store.commit(UPDATE_LINE_TYPE, LINE_TYPE.CDN);
      }
    },
    // 处理页面数据
    handlePlayerInfo() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('share_user_id')) {
        this.share_user_id = urlParams.get('share_user_id');
        // alert(`接收到的111分享用户id:${this.share_user_id}`);
      }
      if (urlParams.get('room_id')) {
        this.room_id = urlParams.get('room_id');
        // alert(`接收到的房间id:${this.room_id}`);
      }
      this.getLiveInfo({ room_id: urlParams.get('room_id') });
    },
    // eslint-disable-next-line camelcase
    async getLiveInfo(params) {
      console.log(params);
      // todo: 处理退出直播间
      await getLiveInfo(params).then(({ data }) => {
        this.$store.commit(SET_SDK_APP_ID, data.sdkAppId);
        this.$store.commit(SET_USER_SIG, data.userSig);
        this.$store.commit(SET_PLAYER_DOMAIN, data.playerDomain);
        this.$store.commit(SET_ROOM_ID, data.roomInfo.roomId);
        this.$store.commit(SET_ROOM_ONLINE_COUNT, data.roomInfo.roomUserSum);
        this.$store.commit(SET_ROOM_NAME, data.roomInfo.roomName);
        this.$store.commit(SET_ROOM_INTRO, data.roomInfo.roomIntro);
        this.$store.commit(SET_ROOM_COVER, data.roomCover);
        this.$store.commit(SET_ANCHOR_USER_ID, data.anchorUserInfo.userId);
        this.$store.commit(SET_ROOM_PROP, data.roomProp);
        this.$store.commit(UPDATE_USER_INFO, {
          userId: data.userInfo.userId,
          userName: data.userInfo.userName,
          userAvatar: data.userInfo.userAvatar,
        });
        this.$store.commit(SET_ANCHOR_USER_INFO, {
          userId: data.anchorUserInfo.userId,
          userName: data.anchorUserInfo.userName,
          userAvatar: data.anchorUserInfo.userAvatar,
        });
        this.coverUrl = data.roomCover;
        this.roomShareImage = data.roomShareImage;
        this.isConfigLoaded = true;

        setTimeout(() => {
          this.$eventBus.$emit('preSetMsg', data.roomMsg);
          // 进直播间拉取最近的群链接消息
          try {
            console.log('进直播间拉取最近的群链接消息',  JSON.parse(this.roomProp).contentBody);
            this.$eventBus.$emit('addGroupLinkPop',  JSON.parse(this.roomProp).contentBody);
          } catch (e) {
            console.log(e);
          }
        }, 3000);
      })
        .catch((res) => {
          // this.$message.error(res.message);
          console.log('失败', res.message);
          if (res.data.code === 4001 && this.$isMobile) {
            // 错误码4001为用户未登录，记录下回跳地址并跳转到登录页
            localStorage.setItem('login_back_url', window.location.href);
            console.log('跳转到登录页');
            window.location.assign('/wap/login/index.html');
          }
        });

      // 获取当前登录用户id
      await getActiveUid().then(({ data }) => {
        // alert(data);
        console.log('获取当前登录用户id成功', data);
        this.uid = data;
        // alert(`当前登录用户id${this.uid}`);
        if (this.uid && this.share_user_id && this.room_id) {
          // alert('分享记录的数据齐了');
          addUserRecommendRecord({
            recipient_user_id: this.uid,
            share_user_id: this.share_user_id,
            room_id: this.room_id,
          }).then((res) => {
            // alert('分享记录成功');
            console.log('分享记录成功', res);
          })
            .catch((res) => {
              // alert(res.message);
              console.log('分享记录失败', res);
            });
        }


        // 获取微信分享配置
        // eslint-disable-next-line camelcase
        const params_url = encodeURIComponent(location.href.split('#')[0]);
        this.getWechatJsSdkConfig({ params_url });
      })
        .catch((res) => {
          console.log('获取当前登录用户id失败', res.message);
        });
    },
    // eslint-disable-next-line camelcase
    async getWechatJsSdkConfig(params) {
      // eslint-disable-next-line no-unused-vars
      await fetchWechatJsSdkConfig(params).then(({ data }) => {
        // eslint-disable-next-line no-param-reassign
        data = JSON.parse(data);
        console.log('微信配置', data);
        wx.config({
          debug: false, // 开启调试模式（开发阶段建议开启）
          appId: data.appId, // 公众号的 AppID
          timestamp: data.timestamp, // 生成签名的时间戳
          nonceStr: data.nonceStr, // 生成签名的随机串
          signature: data.signature, // 签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 需要使用的 JS 接口列表
        });
        wx.ready(() => {
          // 分享到微信好友（聊天窗口）
          console.log('分享到微信好友的接口配置：', {
            title: `【直播中】${this.roomName}`, // 分享标题
            desc: this.roomIntro, // 分享描述
            link: `${window.location.href}&share_user_id=${this.uid}`, // 分享链接，确保与配置的服务器域名一致
            imgUrl: this.roomShareImage, // 分享图标链接
            success: () => {
              console.log('分享到微信好友设置成功');
            },
            fail: (err) => {
              console.error('分享到微信好友设置失败', err);
            },
          });
          wx.updateAppMessageShareData({
            title: `【直播中】${this.roomName}`, // 分享标题
            desc: this.roomIntro, // 分享描述
            link: `${window.location.href}&share_user_id=${this.uid}`, // 分享链接，确保与配置的服务器域名一致
            imgUrl: this.roomShareImage, // 分享图标链接
            success: () => {
              console.log('分享到微信好友设置成功');
            },
            fail: (err) => {
              console.error('分享到微信好友设置失败', err);
            },
          });

          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: `【直播中】${this.roomName}`, // 分享标题
            link: window.location.href, // 分享链接，确保与配置的服务器域名一致
            imgUrl: this.roomShareImage, // 分享图标链接
            success: () => {
              console.log('分享到朋友圈设置成功');
            },
            fail: (err) => {
              console.error('分享到朋友圈设置失败', err);
            },
          });
        });
      })
        .catch((res) => {
          console.log('错误信息', res);
        });
    },
    async handleAppPause() {
      this.isPaused = true;
      this.isConfigLoaded = false;
      this.$store.commit(UPDATE_PLAY_STATE, 'paused');
    },
    async handleRePlay() {
      event.stopPropagation();
      // this.handlePlayerInfo();
      // this.$store.commit(UPDATE_PLAY_STATE, 'playing');
      // this.$forceUpdate();
      window.location.reload();
    },
    // 退出直播间
    async handleExit() {
      // todo: 处理退出直播间
    },
    // 退出登录
    async handleLogout() {
      // todo: 处理退出登录
    },
  },
  created() {
    // 判断是否支持 webrtc, 不支持 webrtc 的网页采用【线路三：标准直播】播放，且不可切换线路
    // this.handleSupportWebRTC();
    // 直接用cdn标准直播
    this.$store.commit(UPDATE_LINE_TYPE, LINE_TYPE.CDN);
    // 处理页面数据
    this.handlePlayerInfo();
    this.$eventBus.$on('appPause', this.handleAppPause);
    // 处理监听事件
    this.$eventBus.$on('exit', this.handleExit);
    this.$eventBus.$on('logout', this.handleLogout);
    this.$eventBus.$on('showLoginCard', this.handleShowLoginCard);
  },
  mounted() {
    try {
      // 动态导入qrcodejs2
      // const QRCode = await import('qrcodejs2');
      if (!this.$isMobile) {
        const url = window.location.href;
        const { qrcodeContainer } = this.$refs;
        // eslint-disable-next-line no-undef
        new QRCode(qrcodeContainer, {
          text: url,
          width: 255,
          height: 255,
        });
      }
    } catch (error) {
      console.error('Failed to load qrcodejs2', error);
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('exit', this.handleExit);
    this.$eventBus.$off('logout', this.handleLogout);
    this.$eventBus.$off('showLoginCard', this.handleShowLoginCard);
  },
};
</script>
<style>
.show-qrcode-tips {
  color: #000;
}
</style>

<style lang="stylus">
@import '~assets/style/black-element-ui.styl';
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  width 100%
  height 100%
  position relative
  text-align center
  overflow auto

// PC页面布局
#app.app-layout
  background-color $backgroundColor
  color $fontColor
  #header
    width 100%
    height 52px
    background-color $themeColor
  #content
    width 100%
    position absolute
    left 0
    bottom 0
    top 52px
    display flex
    #player
      height 100%
      flex-grow 1
      background-color $backgroundColor
    #message
      width 30%
      min-width 300px
      max-width 406px
      height 100%
      background-color $IMThemeColor

// 移动端竖屏布局
#app.mobile-vertical-layout
  color $fontColor
  display flex
  flex-direction column
  #header
    width 100%
    max-height 130px
  #interact
    width 100%
    position absolute
    left 0
    bottom 0
    #message
      height 300px
      z-index 9999
  #content
    flex-grow 1
    display flex
    flex-direction column
    #player
      width 100%
      flex-grow 2
      background-size cover
      background-position center
// 移动端横屏布局
#app.mobile-horizontal-layout
  color $fontColor
  #header
    width 100%
    position absolute
    top 0
    left 0
    z-index 10
    transform translateZ(100px)
  #content
    width 100%
    height 100%
    position relative
    #player
      width 100%
      height 100%
    #message
      position absolute
      bottom 0
      left 0
      width 100%
      height 70%
      pointer-events none
      transform translateZ(100px)
//PC直播间改为扫码观看提示页
#PC-show-qrcode-page
  display flex
  flex-direction column
  justify-content center
  align-items center
  height 80vh
  text-align center
  .show-qrcode-img
    max-width 50%
    margin-bottom 1rem
</style>
