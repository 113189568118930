<!--
 * @Description: 快直播播放
 * @Date: 2021-11-04 11:02:45
 * @LastEditTime: 2022-02-18 21:10:22
-->
<template lang="pug">
div#playerContent
  div.loading(v-show="showLoading")
  div.leb-stream(:key="componentKey")
    video#leb-video-container.stream(playsinline autoplay preload="auto" webkit-playsinline)
</template>

<script>
import {
  PLAY_STATE,
} from '@/constants/room';
import { UPDATE_PLAY_STATE } from '@/constants/mutation-types';
import { isAndroid, isMicroMessenger } from '@/utils/utils';
import { mapState } from 'vuex';
export default {
  name: 'compLebStream',
  data() {
    return {
      player: null,
      isPlaying: false,
      componentKey: 1
    };
  },
  computed: {
    ...mapState({
      playerDomain: 'playerDomain',
      roomId: 'roomId',
      playState: 'playState',
    }),
    showLoading() {
      return this.playState === PLAY_STATE.PLAYING && !this.isPlaying;
    },
  },
  watch: {
    playState(val) {
      if (val === PLAY_STATE.PLAYING) {
        this.player && this.player.play();
      } else if (val === PLAY_STATE.PAUSED) {
        this.player && this.player.pause();
      }
    },
  },
  methods: {
    initPlayer() {
      // eslint-disable-next-line no-undef
      this.player = TCPlayer('leb-video-container', {
        controls: false,
        autoplay: true,
        webrtcConfig: {
          connectRetryCount: 999999
        }
      });
      this.player.src(`webrtc://${this.playerDomain}/live/${this.roomId}?tabr_bitrates=autohd,autosd,autofd&tabr_start_bitrate=autofd&webrtc_tabr_level=server_control`);
      this.playerListener();
    },
    playerListener() {
      this.player.on('error', (error) => {
        console.log(error);
        // window.location.reload();
        this.player.dispose();
        this.componentKey += 1;
        setTimeout(() => {
          this.$nextTick(() => {
            this.isPlaying = true;
            this.initPlayer();
            // this.$store.commit(UPDATE_PLAY_STATE, 'pasued');
          });
        }, 3000);
        // this.$store.commit(UPDATE_PLAY_STATE, 'pasue');
        // this.isPlaying = false;
        // this.player.dispose();
        // setTimeout(() => {
        //   this.$nextTick(() => {
        //     this.componentKey += 1;
        //     this.isPlaying = true;
        //     this.initPlayer();
        //   });
        // }, 1000);
        // console.log('播放器错误', error);
      });
    },
    destroyPlayer() {
      this.player && this.player.dispose();
    },
  },
  created() {

  },
  mounted() {
    // Andriod 微信中 TCPlayer 自动播放会失败，需要手动点击播放
    if (isAndroid && isMicroMessenger) {
      this.$store.commit(UPDATE_PLAY_STATE, 'paused');
    }
    window.addEventListener('error', (event) => {
      if (event.error && event.error.name === 'NotAllowedError') {
        console.error('播放失败：用户未进行交互');
        // 这里可以添加一些恢复或备选方案的代码
      }
    });
    if (this.playerDomain === '') {
      alert(`${this.$t('basic.Please configure your playerDomain')}\r\n\r\nconfig/basic-info-config.js`);
      return;
    }
    this.initPlayer();
  },
  beforeDestroy() {
    this.destroyPlayer();
  },
};
</script>

<style lang="stylus" scoped>
#playerContent
  width 100%
  height 100%
.video_show
  width 100% !important
  height 100% !important
.video_hidden
  width 0.1% !important
  height 0.1% !important
  overflow hidden
.leb-stream
  width 100%
  height 100%
  #leb-video-container
    video
      object-fit cover
  .video-js
    background transparent !important
  .stream
    width 100%
    height 100%
.loading
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    display flex
    &:before
        content "加载中..."
        display block
        color #ffffff
        font-size 24px
        width 100px
        height 30px
        line-height 30px
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
</style>
