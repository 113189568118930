<!--
 * @Description: 移动端 IM 即时通讯组件
 * @Date: 2022-01-25 16:05:22
 * @LastEditTime: 2022-02-21 15:22:10
-->
<template lang="pug">
div.im-message-container
  div.mbr-join(v-if="isJoinShow")
    span {{ join_event }}
  //- 聊天区域
  div.content-top-chat
    div.message-container(ref="box")
      div.single-message-h5(v-for="message, index in messageList")
        div.message-content-container(:class="isMobileHorizontalLayout ? 'dark-background' : ''")
          span.user-name(
           v-if="!isPreMsg(message)"
          :class="isAnchorMessage(message) && 'anchor-name'") {{ getUserNick(message) + ':' }}
          span.sys-name(v-else)
            span(:class="isWarningMsg(message) ? 'warning-name' : 'info-name' ") {{ getUserNick(message) }}
          span.message-content(v-for="item, index in message.renderContent")
            //- 文字消息
            span(
              v-if="item.name === 'text'"
              :key="index"
            ) {{item.content }}
            //- 表情消息
            img.message-icon(
              v-else-if="item.name === 'img'"
              :src="item.src"
              :key="index"
            )
        div.message-send-fail(v-if="message.state === 'fail'" @click="sendMessage(message.content)")
          svg-icon.icon-style(icon-name="error")

  //- 文字及表情输入区域
  div.send-message-container
    //- 表情选择框
    div.emojis-popover(ref="emojiPopover" v-show="showEmojiPopover")
      div.emojis
        div.emoji(
          v-for="emoji in emojiName"
          :key="emoji"
          @click="chooseEmoji(emoji)"
        )
          img(:src="emojiUrl + emojiMap[emoji]")
      div.emojis-popover-arrow
    div.content-bottom
      //- //- 表情icon
      //- span.icon-button(ref="emojiIcon" @click="toggleShowEmojis")
      //-   svg-icon.emoji-icon(icon-name="emoji")
      //- 文字输入框
      div.content-bottom-input
        input.input(
          ref="input"
          type="text"
          v-model="inputMsg"
          :placeholder="inputPlaceHolder"
          @keyup.enter.prevent="handleSendMsg"
          :disabled="isLiveEnded || showLoginAttention "
          required="required"
        )
      //- 发送按钮
      div.send-btn(@click="handleSendMsg" v-if="!showLoginAttention")
        svg-icon.emoji-icon(icon-name="send")

      div.login-attention(v-if="showLoginAttention")
        span {{ $t('Current visitor status') }}
        a(@click="handleLogin") {{ $t('login') }}
</template>

<script>
import tim from './tim.js';
export default {
  name: 'imMobile',
  mixins: [tim],
  props: {
    isMobileVerticalLayout: {
      type: Boolean,
    },
    isMobileHorizontalLayout: {
      type: Boolean,
    },
  },
  data() {
    return {
      showEmojiPopover: false,
      join_event: null,
      isJoinShow: false,
    };
  },
  methods: {
    isWarningMsg(message) {
      if (message.type === 'warning') {
        console.log("警告信息", message);

        return true;
      } else {
        return false;
      }
    },
    isPreMsg(message) {
      if ('type' in message) {
        console.log("预设信息", message);

        return true;
      } else {
        return false;
      }
    },
    toggleShowEmojis() {
      this.showEmojiPopover = !this.showEmojiPopover;
    },
    handleInputSendMsg() {
      // 对于大部分移动浏览器，更改焦点通常会导致键盘收起
      this.$refs.input.blur(); // 假设有一个 ref="input" 的输入框元素
      // 若以上方法无效，可以尝试以下hack方法，但不一定在所有设备上有效
      document.activeElement.blur(); // 移除当前焦点
      window.scrollTo(0, 0); // 滚动到顶部（有时可以帮助关闭键盘）
    }
    // handleHideEmojiPopover(event) {
    //   if (!this.$refs.emojiIcon.contains(event.target) && !this.$refs.emojiPopover.contains(event.target)) {
    //     this.showEmojiPopover = false;
    //   }
    // },
  },
  beforeCreate() {
    // document.addEventListener('click', event => this.handleHideEmojiPopover(event));
  },
  created() {
    this.$eventBus.$on('sendMsg', () => {
      this.handleInputSendMsg();
    });
    this.$eventBus.$on('join_event', (nick) => {
      if (this.isJoinShow) {
        return;
      }
      this.join_event = `${nick}来了`;
      this.isJoinShow = true;
      setTimeout(() => {
        this.isJoinShow = false;
      }, 3000);
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('join_event');
    document.removeEventListener('click', event => this.handleHideEmojiPopover(event));
  },
};
</script>
<style>
@keyframes fadeInAndFadeOut {
  0% {
    transform: translateX(-100%); /* 初始状态，元素位于屏幕左侧之外 */
    opacity: 0; /* 不可见 */
  }
  20% {
    transform: translateX(0); /* 动画一半时间，元素出现在屏幕中 */
    opacity: 1; /* 完全可见 */
  }
  70% {
    transform: translateX(0); /* 动画一半时间，元素出现在屏幕中 */
    opacity: 1; /* 完全可见 */
  }
  100% {
    transform: translateX(100%); /* 动画结束时，元素移至屏幕右侧之外 */
    opacity: 0; /* 再次不可见 */
  }
}

.mbr-join {
  animation-name: fadeInAndFadeOut;
  animation-duration: 3s; /* 总动画时间为3秒 */
  animation-fill-mode: both; /* 动画结束后保持结束状态（隐藏） */
}

</style>
<style lang="stylus" scoped>
.sys-name
  font-weight 600
  font-size 12px
  .warning-name
    padding 2px 5px
    border-radius 5px
    margin 5px 5px 5px 0px
    color #e6a23c
    background-color #fdf6ec
  .info-name
    border-radius 5px
    padding 2px 5px
    color #000
    margin 5px 5px 5px 0px
    background-color #FFFFFF
.mbr-join
  position absolute
  left 20px
  padding 5px 10px
  border-radius 20px
  font-size 14px
  margin-top -40px
  background-color rgba(0,0,0,0.35)
.im-message-container
  width 100%
  height calc(100% - 52px)
  .content-top-chat
    position relative
    width 320px
    height calc(100% - 18px)
    margin 10px 0 8px 18px
    border-radius 10px
    color $fontColor
    font-size 14px
    overflow hidden
    pointer-events all
    .message-container
      position absolute
      bottom 0
      width calc(100% + 10px)
      max-height 100%
      overflow auto
      .single-message-h5
        width 100%
        text-align left
        align-items center
        display flex
        margin-top 5px
        .message-content-container
          align-items center
          background-color rgba(0,0,0,0.3)
          padding 3px 12px
          border-radius 12px
          line-height 18px
          max-width calc(100% - 10px)
          &.dark-background
            background-color rgba(0,0,0,0.35)
          .user-name
            display inline-block
            margin-right 12px
            color #A3EDFF
            &.anchor-name
              color #FFBE8A
          .message-content
            border-radius 4px
            font-size 12px
            word-break break-all
            vertical-align center
            .message-icon
              width 20px
              height 20px
              vertical-align middle
        .message-send-fail
          width 24px
          height 24px
          margin-left 10px
          cursor pointer
          display flex
          align-items center
  .send-message-container
    bottom 20px
    width 100%
    .emojis-popover
      position absolute
      background #FFF
      min-width 150px
      max-width 400px
      width calc(100% - 8px)
      border-radius 4px
      border 1px solid #EBEEF5
      padding 12px
      color #606266
      line-height 1.4
      text-align justify
      font-size 14px
      box-shadow 0 2px 12px 0 rgb(0 0 0 / 10%)
      word-break break-all
      transform translateX(8px) translateY(-100%) translateY(-8px)
      pointer-events all
      .emojis
        height 170px
        max-width 400px
        overflow scroll
        .emoji
          height 30px
          width 30px
          float left
          box-sizing border-box
          img
            width 30px
            height 30px
      &::after
        content ''
        width 0
        height 0
        border 6px transparent solid
        border-top-color #FFF
        position absolute
        left 18px
        top 100%
    .content-bottom
      width 60%
      min-width 217px
      max-width 320px
      height 38px
      border-radius 16px
      background-color rgba(0,0,0,0.3)
      display flex
      align-items center
      // border 1px solid rgba(255,255,255,0.20)
      padding 0 2px 0 6px
      margin-left 15px
      pointer-events all
      span.icon-button
        cursor pointer
        display flex
        .emoji-icon
          width 20px
          height 20px
          fill $fontColor
      div.content-bottom-input
        text-align left
        position relative
        margin 0 6px
        flex-grow 1
        .input
          color #FFF
          top 0
          right 0
          width 100%
          height 100%
          background-color transparent
          font-size 14px
          border none
          outline none
          resize none
          &::-webkit-input-placeholder
            color rgba(255,255,255,0.7)
            font-size 14px
      .send-btn
        padding 2px 4px
        width 50px
        border-radius 16px
        height 26px
        line-height 24px
        font-size 14px
      .login-attention
        position absolute
        left 40px
        font-size 14px
        color $grayFontColor
        line-height 32px
</style>

<i18n>
{
	"en": {
		"Chat now": "Chat now！",
    "Type a message": "Type a message",
    "Current visitor status": "Visitor status, please ",
    "login": "login",
    "Send": "Send",
    "Live room does not exist": "Live room does not exist"
	},
	"zh": {
		"Chat now": "快来互动吧！",
    "Type a message": "文明发言,友善沟通",
    "Current visitor status": "当前为游客身份，请先",
    "login": "登录",
    "Send": "发送",
    "Live room does not exist": "直播间不存在"
	}
}
</i18n>
