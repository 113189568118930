export default {
  install() {
    if (process.env.VUE_APP_DEBUG === 'true') {
      const script = document.createElement('script');
      script.src = '/liveRoom/src/utils/vconsole.min.js';
      script.onload = () => {
        // 确保VConsole库已经加载完毕
        if (window.VConsole) {
          new window.VConsole();
        } else {
          console.warn('VConsole 库加载失败，请检查网络连接.');
        }
      };
      document.head.appendChild(script);
    }
  },
};
