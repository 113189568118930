<!--
 * @Description: 移动端页面header
 * @Date: 2021-10-31 15:59:54
 * @LastEditTime: 2022-02-15 10:58:44
-->
<template lang="pug">
  div.player-title-container
    div.left-container
      div.player-info(@click="drawer = true")
        div.logo-container
          img.logo(:src="anchorUserInfo.userAvatar")
        div.player-title
          span {{ roomName }}
      comp-online-count
      //- comp-line-check
    div.right-container(v-if="false")
      comp-layout(v-if="false")
      comp-language(pageName="player", v-if="false")
      comp-exit
    el-drawer(
      :visible.sync = "drawer"
      direction = "btt"
      :modal="false"
      size="30%"
      :withHeader="false"
      custom-class="room-intro-drawer"
      )
        div#roomIntro
          div.room-intro-title {{ roomName }}
          div.room-intro-content {{ roomIntro }}
</template>

<script>
import compLayout from './comp-layout';
import compLanguage from './comp-language';
import compUserInfo from './comp-user-info';
import compOnlineCount from './comp-online-count';
import compLineCheck from '../comp-player/stream-control/comp-line-check';
import compExit from './comp-exit';
import avatar from 'assets/img/avatar.png';
import logo from 'assets/img/logo.png';
import { mapState } from 'vuex';

export default {
  name: 'compHeader',
  data() {
    return {
      logo,
      avatar,
      drawer: false,
    };
  },
  components: {
    compLayout,
    compLanguage,
    compUserInfo,
    compLineCheck,
    compExit,
    compOnlineCount,
  },
  computed: {
    ...mapState({
      roomName: 'roomName',
      userInfo: 'userInfo',
      roomIntro: 'roomIntro',
      anchorUserInfo: 'anchorUserInfo',
    }),
  },
  methods: {
  },
};
</script>

<style lang="stylus" scoped>

#roomIntro
  color #000
  padding 20px
  .room-intro-title
    color #999
    font-size 14px
    margin-bottom 20px
.player-title-container
  .el-drawer.btt
    border-radius 20px !important
  width 100%
  height 100px
  position absolute
  z-index 99
  display flex
  justify-content space-between
  padding 20px 16px 10px
  .left-container
    .player-info
      height 100%
      display flex
      align-items center
      min-width 140px
      max-width 180px
      height 40px
      background-color rgba(0,0,0,0.3)
      border-radius 24px
      padding-right 10px
      margin-bottom 10px
      .logo-container
        width 36px
        height 36px
        border-radius 50%
        background-color #ffffff
        display flex
        justify-content center
        align-items center
        margin 0 4px 0 2px
      .logo
        width 36px
        height 36px
        border-radius 50%
        object-fit contain
      .player-title
        font-weight medium
        color $fontColor
        font-size 12px
        text-align left
        text-overflow ellipsis
        -o-text-overflow ellipsis
        overflow hidden
  .right-container
    height 100%
    display flex
    align-items flex-start
    & > :not(:first-child)
      margin-left 16px
</style>
