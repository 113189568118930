import { render, staticRenderFns } from "./comp-im-mobile.vue?vue&type=template&id=1024b437&scoped=true&lang=pug&"
import script from "./comp-im-mobile.vue?vue&type=script&lang=js&"
export * from "./comp-im-mobile.vue?vue&type=script&lang=js&"
import style0 from "./comp-im-mobile.vue?vue&type=style&index=0&lang=css&"
import style1 from "./comp-im-mobile.vue?vue&type=style&index=1&id=1024b437&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1024b437",
  null
  
)

/* custom blocks */
import block0 from "./comp-im-mobile.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports