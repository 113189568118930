<!--
 * @Description: 移动端 player 组件
 * @Date: 2021-10-31 16:33:32
 * @LastEditTime: 2022-02-15 15:40:18
-->
<template lang="pug">
div#player-rtc-container.player-rtc-container
  //- 流播放区域
  comp-stream-player.stream-player(ref="streamPlayer")
  div.control-container(@click="handlePause")
    //- 播放按钮
    comp-play(@click.native="handlePlay" v-if="false")
</template>

<script>
import compStreamPlayer from './stream-player';
import compPlay from './stream-control/comp-play';
import { UPDATE_PLAY_STATE } from '@/constants/mutation-types';
export default {
  name: 'compPlayer',
  components: {
    compStreamPlayer,
    compPlay,
  },
  methods: {
    handleExit() {
      this.$refs.streamPlayer.handleExit();
    },
    handlePlay(event) {
      event.stopPropagation();
      this.$store.commit(UPDATE_PLAY_STATE, 'playing');
    },
    handlePause() {
      // 这里暂时移除暂停功能 回头改成显示隐藏聊天消息框
      // this.$store.commit(UPDATE_PLAY_STATE, 'paused');

      // 切换消息框的显示状态，如果当前为false，则提交true，反之亦然
      const newState = !this.$store.state.isMessageBoxVisible;
      this.$store.commit('SET_MESSAGE_BOX_VISIBILITY', newState);
    },
  },
};
</script>

<style lang="stylus" scoped>
.player-rtc-container
  width 100%
  height 100%
  position relative
  overflow hidden
  .stream-player
    width 100%
    height 100%
    overflow hidden
  .control-container
    width 100%
    height 100%
    overflow hidden
    position absolute
    top 0
    left 0
    transform translateZ(100px)
</style>
