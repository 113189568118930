<template lang="pug">
  div.online-count
   span
    svg-icon.icon-style.icon-online(icon-name="online-count")
   span {{ online_count }}人在线
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'compOnlineCount',
  data() {
    return {
      online_count: 0
    };
  },
  components: {
  },
  computed: {
    ...mapState({
      roomId: 'roomId',
      roomOnlineCount: 'roomOnlineCount',
    }),
  },
  methods: {
    async getOnlineCount() {
      const roomId = this.roomId.toString();
      const promise = this.$tim.getGroupOnlineMemberCount(roomId);
      promise.then((imResponse) => {
        this.online_count =  parseInt(this.roomOnlineCount) +  parseInt(imResponse.data.memberCount);
        // console.log('直播间在线报文', imResponse);
        // console.log('直播间当前人数', imResponse.data.memberCount);
        // console.log('直播间ID', this.roomId);
      }).catch((imError) => {
        console.warn('直播间当前人数获取错误getGroupOnlineMemberCount error:', imError); // 获取群在线人数失败的相关信息
      });
    },
    async getOnlineCountInterval() {
      setInterval(() => {
        this.getOnlineCount();
      }, 5000); // 每隔5秒执行一次
    },
  },
  created() {
    this.$eventBus.$on('timReady', this.getOnlineCount);
    this.$eventBus.$on('timReady', this.getOnlineCountInterval);
  },
  beforeDestroy() {
    this.$eventBus.$off('timReady');
  },
};
</script>
<style lang="stylus" scoped>
div.online-count
  display flex
  text-align left
  font-size 10px
.icon-online
  margin-right 2px
</style>
