<!--
 * @Description: 屏幕中央的播放按钮
 * @Date: 2021-12-15 11:59:20
 * @LastEditTime: 2021-12-17 12:06:14
 playState === PLAY_STATE.PAUSED
-->
<template lang="pug">
  transition(name="fade")
    div.replay-content(v-if="playState === PLAY_STATE.PAUSED" :style="{ backgroundImage: `url(${roomCover})` }")
      div.play-background
        div.play-intro
          div.play-button
            div.play-btn
              div.icon-loading
                div.icon-loading1
                  div.icon-line.line1
                  div.icon-line.line2
                  div.icon-line.line3
              span 返回直播间
</template>

<script>
import { mapState } from 'vuex';
import { PLAY_STATE } from '@/constants/room';
export default {
  name: 'compPlay',
  data() {
    return {
      PLAY_STATE,
    };
  },
  computed: {
    ...mapState({
      playState: 'playState',
      roomName: 'roomName',
      roomIntro: 'roomIntro',
      roomCover: 'roomCover',
      anchorUserInfo: 'anchorUserInfo'
    }),
  },
  methods: {
  },
};
</script>

<style lang="stylus" scoped>
  .fade-leave-to
    transform translate(-50%, -50%) scale(1.5) !important
    opacity 0
  .fade-leave-active
    transition all 0.5s ease
  .play-background
    width 100%
    height 100%
    position absolute
    box-shadow 0 8px 16px rgba(0, 0, 0, 0)
    transition transform 0.4s ease-in-out
    backdrop-filter blur(5px)
    top 50%
    left 50%
    transform translate(-50%, -50%)
    background-size cover
    background-position center
    background  linear-gradient( rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 50%)
    cursor pointer
    z-index 99999999
    transform-origin center center
    .play-button
      border-radius 2px
      width 100%
      text-align center
      display flex
      justify-content center
      align-item center
      .play-btn
        border-radius 5000px
        border 1px solid #ff3300
        display flex
        padding 10px 20px
        background-color #F56C6C
        font-size 20px
        font-weight 600

        box-shadow 0 8px 16px rgba(0, 0, 0, 0.3)
</style>
<style>
.badge-item {
  right: 0px
}
.replay-content {position: absolute; width: 100%; height: 100%; background-position: center; background-size: cover;}
.play-intro {

  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  top:-webkit-calc(60% - 100px);
  top:-moz-calc(60% - 100px);
  top:calc(60% - 100px);

}
.room-intro { color: #333; width: 80%; margin: auto; text-align: center; margin-bottom:30px;}

.room-intro  .logo {
margin-bottom:10px;
border: 3px solid #fff;
width:80px;
height:80px;
border-radius:50%;
object-fit:contain;
box-shadow:0 8px 16px rgba(0, 0, 0, 0.3)
}
.play-btn{
  //animation: btn-white 2s ease 2s;
  //animation-fill-mode: forwards;
}
@keyframes btn-white {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
.icon-loading{
  transform: rotate(180deg);
  height: 25px;
  margin-right: 10px;
}

/* loading1 */
.icon-loading1{
  text-align: center;
  display: flex;
  height: 25px;
  justify-content: center;
}
.icon-line{
  margin: 0 2px;
  width: 3px;
  height: 10px;
  background: #fff;
  border-radius: 15px;
}
.line1{
  animation: loading1 1s ease 0s infinite;
}
.line2{
  animation: loading1 1s ease .2s infinite;
}
.line3{
  animation: loading1 1s ease .4s infinite;
}
@keyframes loading1 {
  0% {
    height: 10px;
  }
  50% {
    height: 18px;
  }
  100% {
    height: 10px;
  }
}
</style>
