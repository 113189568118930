<!--
 * @Description: 标准直播播放
 * @Date: 2021-11-04 11:02:45
 * @LastEditTime: 2022-02-18 21:10:30
-->
<template lang="pug">
  div#cdn-stream.cdn-stream
      video#cdn-video-container.stream(playsinline autoplay preload="auto" webkit-playsinline)
      div.loading(v-show="showLoading")
</template>

<script>
import {
  PLAY_STATE,
} from '@/constants/room';
import { UPDATE_PLAY_STATE } from '@/constants/mutation-types';
import { isAndroid, isMicroMessenger } from '@/utils/utils';
import { mapState } from 'vuex';
export default {
  name: 'compCdnStream',
  data() {
    return {
      player: null,
      componentKey: 1,
      isPlaying: false
    };
  },
  computed: {
    ...mapState({
      sdkAppId: 'sdkAppId',
      playerDomain: 'playerDomain',
      playState: 'playState',
      roomId: 'roomId',
      roomCover: 'roomCover'
    }),
    showLoading() {
      return this.playState === PLAY_STATE.PLAYING && !this.isPlaying;
    },
  },
  watch: {
    playState(val) {
      if (val === PLAY_STATE.PLAYING) {
        this.player && this.player.play();
      } else if (val === PLAY_STATE.PAUSED) {
        this.player && this.player.pause();
      }
    },
  },
  methods: {
    initPlayer() {
      // this.player && this.player.dispose();
      // eslint-disable-next-line no-undef
      this.player = TCPlayer('cdn-video-container', {
        controls: false,
        autoplay: true,
        // poster: this.roomCover,
        webrtcConfig: {
          connectRetryCount: 999999
        }
      });
      this.player.src(`https://${this.playerDomain}/live/${this.roomId}_autocode.m3u8`);
      this.playerListener();
    },
    reInitPlayer() {
      try {
        this.isPlaying = false;
        setTimeout(() => {
          this.$nextTick(() => {
            this.isPlaying = true;
            this.initPlayer();
          });
        }, 1000);
      } catch (e) {
        console.warn(e);
      }
    },
    playerListener() {
      this.player.on('waiting', (e)=> {
        console.log('播放停止，下一帧内容不可用时触发。', e);
        this.player.src(`https://${this.playerDomain}/live/${this.roomId}_autocode.m3u8`);
      });
      this.player.on('canplay', (e)=> {
        console.log('当播放器能够开始播放视频时触发。', e);
        // this.reInitPlayer();
      });
      this.player.on('error', (e) => {
        console.log('播视频播放出现错误时触发。', e);
        this.reInitPlayer();
      });
      this.player.on('pause', (e)=> {
        console.log('暂停时触发。', e);
        this.player && this.player.play();
      });
      this.player.on('blocked', (e)=> {
        console.log('自动播放被浏览器阻止时触发。', e);
      });
      this.player.on('ended', (e)=> {
        console.log('视频播放已结束时触发。此时 currentTime 值等于媒体资源最大值。', e);
      });
      this.player.on('loadeddata', (e)=> {
        console.log('当前帧的数据已加载，但没有足够的数据来播放视频的下一帧时，触发该事件。', e);
      });
      this.player.on('loadedmetadata', (e)=> {
        console.log('已加载视频的 metadata。', e);
      });
      this.player.on('playing', (e)=> {
        console.log('因缓冲而暂停或停止后恢复播放时触发', e);
      });
      this.player.on('loadstart', (e)=> {
        console.log('开始加载数据时触发。', e);
      });
      this.player.on('durationchange', (e)=> {
        console.log('视频的时长数据发生变化时触发。', e);
      });
    },
    destroyPlayer() {
      this.player && this.player.dispose();
    },
  },
  created() {
    // Andriod 微信中 TCPlayer 自动播放会失败，需要手动点击播放
    if (isAndroid && isMicroMessenger) {
      this.$store.commit(UPDATE_PLAY_STATE, 'paused');
    }
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'visible') {
        // this.isPlaying = true;
        this.player && this.player.muted(false);
        this.player && this.player.play();
        console.log("在前台");
      } else {
        console.log("在后台");
        this.isPlaying = false;
        this.player && this.player.muted(true);
        this.player && this.player.pause();
      }
    });
    setInterval(()=> {
      if (document.visibilityState === 'visible') {
        this.isPlaying = true;
        this.player && this.player.muted(false);
        this.player && this.player.play();
        console.log("在前台");
      }
    }, 5000);
  },
  mounted() {
    if (this.playerDomain === '') {
      alert(`${this.$t('basic.Please configure your playerDomain')}\r\n\r\nconfig/basic-info-config.js`);
      return;
    }
    this.reInitPlayer();
  },
  beforeDestroy() {
    this.destroyPlayer();
  },
};
</script>

<style>
.video-js { background-color: rgba(0,0,0,0.3) !important;}
</style>

<style lang="stylus">
#playerContent
  width 100%
  height 100%
.cdn-stream
  width 100%
  height 100%
  #cdn-video-container
    video
      object-fit cover
      background-size cover
      //background-color rgba(255,255,255,0.5)
  .stream
    width 100%
    height 100%
  .loading
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    display flex
    &:before
        content "加载中..."
        display block
        color #ffffff
        font-size 24px
        width 100px
        height 30px
        line-height 30px
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
</style>
