import request from './request';


/**
 * @description 获取直播信息
 */
// eslint-disable-next-line camelcase
export function getLiveInfo(data) {
  return request.get('live_room/get_live_info', data);
}


// /**
//  * @description 获取直播间信息
//  */
//  export function memberEquityStatusApi(id, status) {
//   return request.post(`svip/interests/status/${id}`, { status });
// }
/**
 * @description 获取微信公众号js-sdk配置
 */
// eslint-disable-next-line camelcase
export function fetchWechatJsSdkConfig(data) {
  return request.post('live_room/get_wechat_js_sdk_config', data);
}

/**
 * @description 获取当前登录的用户id
 */
// eslint-disable-next-line camelcase
export function getActiveUid(data) {
  return request.get('live_room/get_active_uid', data);
}

/**
 * @description 添加用户分享直播间网页记录接口
 */
// eslint-disable-next-line camelcase
export function addUserRecommendRecord(data) {
  return request.post('live_room/add_user_recommend_record', data);
}
